import React from 'react'
import './testimonials.css'
import HUB1 from '../../assets/sid1.jpg'
import HUB8 from '../../assets/sid8.png'
import HUB7 from '../../assets/sid7.png'
import HUB2 from '../../assets/sid2.png'

// import Swiper core and required modules
import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    sid: HUB1,
    name: 'SIDNEY TURAY',
    review: 'As a Final-Year student pursing a B.Sc in Computer Science at the University of Makeni (UniMak), i have developed a strong sense of discipline when it comes to Software Engineering Matters. I have been entrusted with managing petty cash within our Technology Donate'
  },
  {
    sid: HUB7,
    name: 'ABASS A. CONTEH',
    review: 'As a Final-Year student pursing a B.Sc in Computer Science at the University of Makeni (UniMak), i have developed a strong sense of discipline when it comes to Development App Matters. I have been entrusted with managing petty cash within our Technology Donate'
  },
  {
    sid: HUB8,
    name: 'PETER SIDIKIE GBLA',
    review: 'As a Final-Year student pursing a B.Sc in Computer Science at the University of Makeni (UniMak), i have developed a strong sense of discipline when it comes to Article Matters. I have been entrusted with managing petty cash within our Technology Donate'
  },
  {
    sid: HUB2,
    name: 'ALEX JUNIOR BANGURA',
    review: 'As a Final-Year student pursing a B.Sc in Computer Science at the Enrest Bai Koroma University Technology And Science of Makeni (EBKUTS), i have developed a strong sense of discipline when it comes to Software Engineering Matters. I have been entrusted with managing petty cash within our Technology Donate'
  },
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials_container"
      // install Swiper modules
      modules={[ Pagination ]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({sid, name, review}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className="client_avatar">
                  <img src={sid}/>
                </div>
                <h5 className='client_name'>{name}</h5>
                  <small className='client_review'>{review}</small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials