import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FiDribbble} from 'react-icons/fi'
import { FaFacebook } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import {BsWhatsapp} from 'react-icons/bs'


const HeaderSocials = () => {
  return (
    <div className='header_socials'>
        <a href="https://linkedin.com" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com" target="_blank"><FaGithub/></a>
        <a href="https://dribbble.com" target="_blank"><FiDribbble/></a>
        <a href="https://facebook.com" target="_blank"><FaFacebook/></a>
        <a href="https://react-icons-v2.vercel.app/" target="_blank"><FaReact/></a>
        <a href="https://www.youtube.com/@SignLanguagePeopleOrganization" target="_blank"><BsYoutube /></a>
        <a href="https://api.whatsapp.com" target='_blank'><BsWhatsapp /></a>
    </div>
  )
}

export default HeaderSocials