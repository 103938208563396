import React from 'react'
import './experience.css'
import { AiFillHtml5 } from 'react-icons/ai'
import { RiJavascriptFill } from 'react-icons/ri'
import { IoLogoCss3 } from 'react-icons/io'
import { BiLogoBootstrap } from 'react-icons/bi'
import { FaAngular } from 'react-icons/fa'
import { FaReact } from 'react-icons/fa'
import { FaNodeJs } from 'react-icons/fa'
import { SiPhp } from 'react-icons/si'
import { FaPython } from 'react-icons/fa'
import { SiMongodb } from 'react-icons/si'
import { GrMysql } from 'react-icons/gr'
import { FaJava } from 'react-icons/fa6'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience_container">
        <div className="experience_fronted">
          <h3>Fronted Development</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <AiFillHtml5 className='experience_detials-icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/html" target='_blank'>Experience</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <IoLogoCss3 className='experience_detials-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/css" target='_blank'>Intermediate</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <RiJavascriptFill className='experience_detials-icon' />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/js/default.asp" target='_blank'>Experience</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <BiLogoBootstrap className='experience_detials-icon' />
              <div>
                <h4>Bootstrap</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/bootstrap" target='_blank'>Experience</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <FaAngular className='experience_detials-icon' />
              <div>
                <h4>Angular</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/angular" target='_blank'>Experience</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <FaReact className='experience_detials-icon' />
              <div>
                <h4>React</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/react" target='_blank'>Experience</a>
                </small>
              </div>
            </article>
          </div>
        </div>
        {/* END OF FRONTEND */}

        <div className="experience_backend">
        <h3>Bcakend Development</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <FaNodeJs className='experience_detials-icon' />
              <div>
                <h4>Node Js</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/nodejs" target='_blank'>Experience</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <SiMongodb className='experience_detials-icon' />
              <div>
                <h4>MongoDB</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/mongodb" target='_blank'>Intermediate</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <SiPhp className='experience_detials-icon' />
              <div>
                <h4>PHP</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/php" target='_blank'>Intermediate</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <GrMysql className='experience_detials-icon' />
              <div>
                <h4>MySQL</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/mysql" target='_blank'>Basic</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <FaPython className='experience_detials-icon' />
              <div>
                <h4>Python</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/python" target='_blank'>Experience</a>
                </small>
              </div>
            </article>
            <article className='experience_details'>
              <FaJava className='experience_detials-icon' />
              <div>
                <h4>Java</h4>
                <small className='text-light'>
                  <a href="https://www.w3schools.com/java" target='_blank'>Experience</a>
                </small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience