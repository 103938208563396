import React from 'react'
import './services.css'
import { BsFillClipboardCheckFill } from 'react-icons/bs'
import { SiOctave } from "react-icons/si"; 
import { SiGooglecontaineroptimizedos } from 'react-icons/si'     
import { MdPriceChange } from 'react-icons/md'     
import { FaBlog } from 'react-icons/fa'
import { RiTeamFill } from 'react-icons/ri'
import { SiApacherocketmq } from 'react-icons/si' 
import { SiDjango } from 'react-icons/si' 
import { FaAngular } from 'react-icons/fa' 
import { FaReact } from 'react-icons/fa' 
import { FaPython } from 'react-icons/fa' 
import { SiPhp } from 'react-icons/si' 
import { GrMysql } from 'react-icons/gr' 
import { SiMongodb } from 'react-icons/si'
import { IoInformationCircleSharp } from 'react-icons/io5'
import { GrBlog } from 'react-icons/gr'
import { MdOutlineAppRegistration } from 'react-icons/md'
import { TfiWrite } from 'react-icons/tfi'                                        
import { GrOptimize } from 'react-icons/gr'
import { SiPrometheus } from 'react-icons/si'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services_container">
        <article className='service'>
          <div className="service_head">
            <h3><b>UI/UX Design</b></h3>
          </div>

          <ul className="service_list">
            <li>
              <SiOctave className='service_list-icon'  />
              <p className='text-light'>
                <a href="https://www.niceverynice.com/components-categories/cta" target='_blank'>CTA UI/UX Patterns</a>
              </p>
            </li>
            <li>
              <SiGooglecontaineroptimizedos className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.niceverynice.com/components-categories/ecommerce" target='_blank'>ECOMMERCE UI/UX Patterns</a>
              </p>
            </li>
            <li>
              <MdPriceChange className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.niceverynice.com/components-categories/pricing" target='_blank'>PRICING UI/UX Patterns</a>
              </p>
            </li>
            <li>
              <FaBlog className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.niceverynice.com/components-categories/blog" target='_blank'>BLOG UI/UX Patterns</a>
              </p>
            </li>
            <li>
              <RiTeamFill className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.niceverynice.com/components-categories/team" target='_blank'>TEAM UI/UX Patterns</a>
              </p>
            </li>
            <li>
              <SiApacherocketmq className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.niceverynice.com/components-categories/hero" target='_blank'>HERO UI/UX Patterns</a>
              </p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className='service'>
          <div className="service_head">
            <h3>WEB Development</h3>
          </div>

          <ul className="service_list">
            <li>
              <SiDjango className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.djangoproject.com" target='_blank'>DJANGO WEB Developer</a>
              </p>
            </li>
            <li>
              <FaAngular className='service_list-icon' />
              <p className='text-light'>
                <a href="https://angular.io" target='_blank'>ANGULAR WEB Developer</a>
              </p>
            </li>
            <li>
              <FaReact className='service_list-icon' />
              <p className='text-light'>
                <a href="https://react.dev" target='_blank'>REACT WEB Developer</a>
              </p>
            </li>
            <li>
              <FaPython className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.python.org" target='_blank'>PYTHON WEB Developer</a>
              </p>
            </li>
            <li>
              <SiPhp className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.scriptcase.net" target='_blank'>PHP WEB Developer</a>
              </p>
            </li>
            <li>
              <GrMysql className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.mysql.com" target='_blank'>MYSQL WEB Developer</a>
              </p>
            </li>
            <li>
              <SiMongodb className='service_list-icon' />
              <p className='text-light'>
                <a href="https://www.mongodb.com" target='_blank'>MONGODB WEB Developer</a>
              </p>
            </li>
          </ul>
        </article>
        {/* WEB DEVELOPMENT */}
        <article className='service'>
          <div className="service_head">
            <h3>Content Creation</h3>
          </div>

          <ul className="service_list">
            <li>
              <IoInformationCircleSharp className='service_list-icon' />
              <p className='text-light'>
                <a href="https://intergrowth.com/content-marketing/content-development/#2" target='_blank'>INFORMATION Creation </a>
              </p>
            </li>
            <li>
              <GrBlog className='service_list-icon' />
              <p className='text-light'>
                <a href="https://intergrowth.com/content-marketing/content-development/#3" target='_blank'>BLOG Analysis Creation </a>
              </p>
            </li>
            <li>
              <MdOutlineAppRegistration className='service_list-icon' />
              <p className='text-light'>
                <a href="https://intergrowth.com/content-marketing/content-development/#4" target='_blank'>STRATEGIZE Creation</a>
              </p>
            </li>
            <li>
              <TfiWrite className='service_list-icon' />
              <p className='text-light'>
                <a href="https://intergrowth.com/content-marketing/content-development/#5" target='_blank'>WRITE Creation</a>
              </p>
            </li>
            <li>
              <GrOptimize className='service_list-icon' />
              <p className='text-light'>
                <a href="https://intergrowth.com/content-marketing/content-development/#6" target='_blank'>OPTIMIZE Creation</a>
              </p>
            </li>
            <li>
              <SiPrometheus className='service_list-icon' />
              <p className='text-light'>
                <a href="https://intergrowth.com/content-marketing/content-development/#7" target='_blank'>PROMOTE Creation</a>
              </p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services