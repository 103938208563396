import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import { BsYoutube } from 'react-icons/bs'
import {BsLinkedin} from 'react-icons/bs'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
       <a href="#" className='footer_logo'>!Deaf Sidney Turay</a> 

       <ul className='permalinks'>
        <li><a href="#"><b>Home</b></a></li>
        <li><a href="#about"><b>About</b></a></li>
        <li><a href="#experience"><b>Experience</b></a></li>
        <li><a href="#services"><b>Services</b></a></li>
        <li><a href="#portfolio"><b>Portfolio</b></a></li>
        <li><a href="#testimonials"><b>Testimonials</b></a></li>
        <li><a href="#contact"><b>Contact</b></a></li>
       </ul>

       <div className="footer_socials">
        <a href="https://facebook.com" target='_blank'><FaFacebookF /></a>
        <a href="https://instagram.com" target='_blank'><FiInstagram /></a>
        <a href="https://twitter.com" target='_blank'><IoLogoTwitter /></a>
        <a href="https://www.youtube.com" target='_blank'><BsYoutube /></a>
        <a href="https://linkedin.com" target='_blank'><BsLinkedin /></a>
        <a href="https://www.messenger.com" target='_blank'><RiMessengerLine /></a>
        <a href="https://api.whatsapp.com" target='_blank'><BsWhatsapp /></a>
       </div>

       <div className="footer_copyright">
        <small><b>&copy; !Deaf Sidney TurayCodeTechn iDSCT@. All rights resvered.</b></small>
       </div>
    </footer>
  )
}

export default Footer