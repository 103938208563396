import React from 'react'
import './about.css'
import ME from '../../assets/me-about-1.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about_content">
          <div className="about_cards">
            <article className='about_card'>
              <FaAward className='about_icon' />
              <h5><b>Experience</b></h5>
              <small><b>4+ Years Working</b></small>
            </article>

            <article className='about_card'>
              <FiUsers className='about_icon' />
              <h5><b>Clients</b></h5>
              <small><b>200+ Worldwide</b></small>
            </article>

            <article className='about_card'>
              <VscFolderLibrary className='about_icon' />
              <h5><b>Projects</b></h5>
              <small><b>90+ Completed</b></small>
            </article>
          </div>

          <p>
            I <b>Michael Sidney Turay</b> I am writting to kindly request your permission to express my interest
            in serving as the <b>Software Enginerring, Website Application and Data Science</b> for <b>Sierra Leone 
            Deaf Community</b>. I am <b>Hearing Impaired</b> and I live in <b>Makeni.</b>
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About